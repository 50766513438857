@mixin link($color: gold, $text-decoration: false) {
  color: var(--#{$color});
  transition: var(--link-color-transition);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: var(--link-color-#{$color}-hover);
  }

  @if $text-decoration {
    text-decoration: underline;
  }
}

@function btnGradient($fromColor, $toColor) {
  @return linear-gradient(251deg, $fromColor 19%, $toColor 75%);
}

@mixin btnBgColor($bgColor, $disabled: false) {
  @if $disabled == true and $bgColor != transparent {
    background-color: var(--gray-500) !important;
    color: var(--black) !important;
    border-color: transparent;
    pointer-events: none;
  } @else if $bgColor == blue {
    background: btnGradient(var(--brand-primary-200), var(--brand-primary));
    border-color: transparent;
    color: var(--black);
    z-index: 1;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      background: btnGradient(var(--brand-primary), var(--brand-primary-200));
      transition: opacity 0.2s linear;
      border-radius: 40px;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  } @else if $bgColor == black {
    background-color: var(--black);
  } @else if $bgColor == transparent {
    background-color: transparent;

    @if $disabled == true {
      background-color: transparent !important;
      color: var(--gray-600) !important;
      border-color: var(--gray--500) !important;
      pointer-events: none;
    } @else {
      color: var(--white);
      border-color: var(--gray-400);

      &:hover {
        color: var(--black);
        background-color: var(--white);
      }
    }
  } @else if $bgColor == white {
    color: var(--black);
    background-color: var(--white);
    border-color: var(--white);

    &:hover {
      background-color: var(--black);
      color: var(--white);
      border-color: var(--gray-400);
    }
  }
}

@mixin btnSize($size) {
  @if $size == lg {
    padding: 18px 33px;
    font-size: var(--font-size-28);
    border-radius: 48px;
  } @else if $size == md {
    padding: 8px 24px;
    border-radius: 30px;
    font-size: var(--font-size-24);
  } @else if $size == sm {
    padding: 8px 24px;
    font-size: var(--font-size-16);
    border-radius: 30px;
  } @else if $size == xs {
    padding: 8px 16px;
    font-size: var(--font-size-14);
    border-radius: 30px;
  }
}

@mixin heading($size) {
  width: 100%;
  margin: 0;
  line-height: 1;

  @if $size == title {
    font-size: var(--font-size-title-mobile);
    font-weight: var(--font-weight-ultra-light);

    @include breakpoint-up(md) {
      font-size: var(--font-size-title-desktop);
    }
  }

  @if $size == 1 {
    font-size: var(--font-size-h1-mobile);
    font-weight: var(--font-weight-ultra-light);

    @include breakpoint-up(md) {
      font-size: var(--font-size-h1-desktop);
    }
  }

  @if $size == 2 {
    font-size: var(--font-size-h2-mobile);
    font-weight: var(--font-weight-light);
    margin-bottom: 25px;
    margin-top: 30px;

    @include breakpoint-up(md) {
      font-size: var(--font-size-h2-desktop);
      margin-bottom: 35px;
    }
  }

  @if $size == 3 {
    font-size: var(--font-size-h3-mobile);
    font-weight: var(--font-weight-light);
    margin-bottom: 19px;
    margin-top: 45px;

    @include breakpoint-up(md) {
      font-size: var(--font-size-h3-desktop);
    }
  }

  @if $size == 4 {
    font-size: var(--font-size-h4-mobile);
    font-weight: var(--font-weight-light);

    @include breakpoint-up(md) {
      font-size: var(--font-size-h4-desktop);
    }
  }

  @if $size == 5 {
    font-size: var(--font-size-h5-desktop);
    font-weight: var(--font-weight-light);
    margin-bottom: 20px;

    @include breakpoint-up(md) {
      font-size: var(--font-size-h5-mobile);
    }
  }

  @if $size == 6 {
    font-size: var(--font-size-h6);
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: 10px;
  }
}

@mixin body-copy($size: 'md') {
  font-weight: var(--font-weight-light);

  @if $size == lg {
    font-size: var(--font-size-16);

    @include breakpoint-up(sm) {
      font-size: var(--font-size-24);
    }

    @include breakpoint-up(md) {
      font-size: var(--font-size-28);
    }
  } @else if $size == md {
    font-size: var(--font-size-24);
  } @else if $size == sm {
    font-size: var(--font-size-16);
  }
}

@mixin btn($size: 'md', $bgColor: 'white', $disabled: false) {
  @include btnSize($size);
  @include btnBgColor($bgColor, $disabled);
  transition: var(--link-color-transition);
  position: relative;
  display: block;
  cursor: pointer;
  border: 1px solid transparent;
  font-weight: var(--font-weight-light);
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover,
  &:hover:after {
    transition: var(--link-color-transition);
  }

  @if $disabled == true {
    pointer-events: none;
  }
}

@mixin svgBtn($color: white) {
  height: var(--closeButtonWidthMobile);
  width: var(--closeButtonWidthMobile);
  max-height: var(--closeButtonWidthMobile);
  max-width: var(--closeButtonWidthMobile);
  margin: 0;

  @include breakpoint-up(lg) {
    height: var(--closeButtonWidthDesktop);
    width: var(--closeButtonWidthDesktop);
    max-height: var(--closeButtonWidthDesktop);
    max-width: var(--closeButtonWidthDesktop);
  }

  @if $color == white {
    @include link(white);
  } @else if $color == black {
    @include link(black);
  }
}

@mixin arrow($direction, $size: 'md') {
  display: inline-block;
  border-style: solid;
  border-color: transparent transparent var(--white) transparent;

  @if $size == md {
    border-width: 0 8px 14px 8px;
  }

  @if $size == sm {
    border-width: 0 6px 8px 6px;
  }

  @if $direction == left {
    transform: rotate(-90deg);
  }

  @if $direction == right {
    transform: rotate(90deg);
  }

  @if $direction == up {
    transform: rotate(0deg);
  }

  @if $direction == down {
    transform: rotate(180deg);
  }
}

$margins: (
  '0': 0,
  '1': var(--spacing-1),
  '2': var(--spacing-2),
  '3': var(--spacing-3),
  '4': var(--spacing-4)
);

$margins_headings: (
  '0': 0,
  '1': var(--spacing-1),
  '2': var(--spacing-2)
);

@each $margin-level, $margin-value in $margins {
  .margin-top-#{$margin-level} {
    margin-top: $margin-value;
  }

  .margin-bottom-#{$margin-level} {
    margin-bottom: $margin-value;
  }
}
