/* 
 * @TODO: Move this css to Form.module.scss. 
 * Currently only works from this file.
 */
@import '../styles/mixins.scss';
@import '../styles/variables.breakpoints.module.scss';

$form-elements-full-width: "input[type='text'], input[type='name'], input[type='email'], input[type='tel'], input[type='number'], input[type='name'], input[type='date'], input[type='datetime'], select, textarea";

.fs-form {
  width: 100%;
  max-width: 725px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;

  fieldset.fs-fieldset,
  fieldset.fs-fieldset__section {
    margin-bottom: var(--spacing-1);
    border: none;
    padding: 0;
    color: var(--white);

    &.fs-fieldset--required label {
      position: relative;

      &:after {
        content: '*';
        position: absolute;
        z-index: 1;
        right: -8px;
        top: 0;
        color: var(--white);
      }
    }
  }

  .fs-section {
    span {
      p {
        margin-bottom: 15px;

        strong {
          @include heading(3);
        }

        + p {
          margin-bottom: 0;
        }
      }
    }
  }

  #{$form-elements-full-width} {
    width: 100%;
    padding: 0.48rem 0.8rem;
    display: block;
    border-radius: 30px;
    border-width: 1px;
    border-style: solid;
    font-size: var(--font-size-16);
    transition: border-color 0.5s ease;
    height: 42px;
    border: 1px solid #474747;
    border-radius: 8px;
    
    &:focus {
      outline: none;
    }
  }

  &.fs-form--single-email-field {
    width: 100%;
    position: relative;
    margin: 0 auto;

    input[type='email'] {
      padding-right: 85px;
      font-size: var(--font-size-16);
    }

    button {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.formstack--input-bg-color-black {
    #{$form-elements-full-width} {
      background-color: var(--black) !important;
      color: var(--white);
      border-color: var(--gray-700);
      /* @TODO: wrap inputs in react-formstack package to leverage the filters. */
      // filter: drop-shadow(0px 1px 16px rgba(165, 153, 111, 0.5));

      &:focus {
        border-color: var(--white);
      }

      &::placeholder {
        color: var(--gray-700);
      }

      // Prevent webkit from changing input color after autofill
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px var(--black) inset;
        -webkit-text-fill-color: var(--white);
      }
    }

    // Make webkit datetime calendar icon white
    .fs-fieldset__datetime {
      ::-webkit-calendar-picker-indicator {
        filter: invert(0.7);
      }
    }
  }

  &.formstack--input-bg-color-white {
    #{$form-elements-full-width} {
      background-color: var(--white);
      color: var(--black);

      &::placeholder {
        color: var(--gray-300);
      }
    }
  }

  &.formstack--input-bg-color-transparent {
    #{$form-elements-full-width} {
      background: transparent;
      color: var(--white);
      border-color: var(--gray-700);

      &::placeholder {
        color: var(--gray-300);
      }

      // prevent webkit from changing input color after autofill
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px var(--gold-500) inset;
        -webkit-text-fill-color: var(--black);
      }
    }

    .fs-fieldset__datetime {
      //keeps input same height as other inputs on mobile
      input[type='date'] {
        min-height: 36px;
      }
      ::-webkit-calendar-picker-indicator {
        filter: invert(0.7);
      }
    }
  }

  label {
    display: inline-block;
    margin-bottom: 3px;
    font-size: var(--font-size-16);

    &.fs-label--hidden {
      display: none;
    }
  }

  textarea {
    min-height: 200px;
  }

  .fs-fieldset__select {
    .fs-field-wrapper {
      position: relative;
      display: block;

      &:after,
      &:before {
        content: '';
        position: absolute;
        bottom: 15px;
        width: 1px;
        height: 10px;
        background-color: var(--gray-700);
      }

      &:before {
        right: 28px;
        transform: rotate(140deg);
      }

      &:after {
        right: 22px;
        transform: rotate(40deg);
      }
    }

    select {
      appearance: none; // hide native browser caret
      cursor: pointer;
    }
  }

  .fs-fieldset__checkbox,
  .fs-fieldset__radio {
    ul {
      margin-bottom: 0;
      margin-top: 7px;
      padding-left: 0;
      list-style: none;
      width: 100%;

      li {
        width: 100%;
        display: grid;
        grid-template-columns: 20px 1fr;
        align-items: flex-start;
        margin-right: var(--spacing-1);

        @include breakpoint-up(sm) {
          &:nth-child(odd) {
            margin-right: 0;
          }
        }
      }

      label {
        margin-left: 10px;
        text-transform: none;

        &:after {
          content: none !important;
        }
      }
    }
  }

  .fs-fieldset__checkbox {
    input {
      &:after {
        /* @TODO: customize checkboxes */
      }
    }
  }

  .fs-fieldset__name {
    legend {
      display: none;
    }

    #fs-subfield-name-last {
      @include breakpoint-up(md) {
        //margin-right: var(--spacing-1);
        margin-top: var(--spacing-1);
      }
    }
    //commented out to make single line fields per designs
    // #fs-subfield-name-first,
    // #fs-subfield-name-last {
    //   @include breakpoint-up(md) {
    //     position: relative;
    //     width: calc(50% - var(--spacing-1) / 2);
    //     display: inline-block;
    //   }
    // }
  }

  @include breakpoint-down(sm) {
    .fs-field__subfield + .fs-field__subfield {
      margin-top: var(--spacing-1);
    }
  }

  // @TODO: figure out a cleaner way to style the option-in checkbox above submit button
  // Perhaps pass a class from Formstack?
  .fs-fieldset__checkbox:last-of-type > .fs-label--hidden + .fs-field-wrapper {
    p {
      font-size: var(--font-size-14);
    }

    ul {
      width: 100%;

      li {
        width: 100%;
      }

      label {
        display: inline;
      }
    }
  }

  button[type='submit'] {
    margin-top: var(--spacing-2);
    width: 100%;
    background: btnGradient(var(--brand-primary-200), var(--brand-primary));
    border-color: transparent;
    color: var(--black);
    z-index: 1;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      background: btnGradient(var(--brand-primary), var(--brand-primary-200));
      transition: opacity 0.2s linear;
      border-radius: 40px;
    }

    &:hover {
      color: var(--black)!important;
      border-color: transparent!important;  
      &:before {
        opacity: 1;
      }
    }
    @include breakpoint-up(md) {
      width: auto;
    }
    // margin-right: auto;
    // margin-left: auto;
  }

  &.formstack--submit-btn-bg-white {
    button[type='submit'] {
      @include btn(sm, white);
    }
  }

  &.formstack--submit-btn-bg-transparent {
    button[type='submit'] {
      @include btn(sm, transparent);
    }
  }

  .fs-section {
    // h2 comes from formstack
    h2 {
      @include heading(3);
      margin-top: 0;
      margin-bottom: var(--spacing-1);
    }
    p {
      margin-bottom: 0;
    }
  }
}

// Success message is not a child of the form.
// This is some hacky css to make the message appear near submit button and retain the form's bottom margin.
// @TODO: reorganize HTML structure
.formstack-success {
  &.is-child-of-page-content {
    text-align: center;
  }

  h3 {
    margin: var(--spacing-0);
    padding-top: var(--spacing-1);
  }

  p {
    padding-bottom: var(--spacing-1);
    padding-top: var(--spacing-1);
  }

  button {
    font-weight: var(--font-weight-bold);
  }
}
