/* @TODO: finish removing camel case from css vars to allow for cleaner mixins. */
html {
  /* colors */
  --black: #000000;
  --black-transparent: rgba(31, 31, 31, 0.5);

  --blue-100: #9affff;
  --blue-200: #00ffff;
  --blue-300: #00dfff;
  --blue-400: #00acff;
  --blue-500: #0084d5;
  --blue-600: #005c95;
  --blue-700: #004168;
  --blue-800: #002d49;
  --blue-900: #002033;

  --gold-100: #ffffc5;
  --gold-200: #ffff98;
  --gold-300: #fff38a;
  --gold-400: #ffd05a;
  --gold-500: #c9a045;
  --gold-600: #8d7030;
  --gold-700: #624e22;
  --gold-800: #453718;
  --gold-900: #302611;

  --gray-100: #e2e2e2;
  --gray-200: #c6c6c6;
  --gray-300: #ababab;
  --gray-400: #919191;
  --gray-500: #757575;
  --gray-600: #5e5e5e;
  --gray-700: #474747;
  --gray-800: #303030;
  --gray-900: #1b1b1b;

  --white: #ffffff;

  --aero-300: #20ffff;
  --aero-500: #05c3dd;

  --brand-primary: var(--aero-500);
  --brand-primary-200: var(--aero-300);
  --brand-secondary: var(--blue-500);

  /* Gradient colors, more cleanup necessary */
  --header-bg-color: linear-gradient(180deg, var(--black) 70.3%, rgba(3, 3, 4, 0) 100%);
  --header-subnav-bg-color: radial-gradient(96.81% 96.81% at 50% 50%, rgba(217, 217, 217, 0.1) 0%, rgba(217, 217, 217, 0) 100%);
  --hero-fade-bottom-color: linear-gradient(180deg, rgba(4, 4, 5, 0) 6.83%, var(--black) 74.06%);
  --box-shadow-glow: 0px 0px 100px rgba(255, 255, 255, 0.3);
  --gold-gradient: linear-gradient(180deg, var(--gold-500) 4.58%, var(--gold-800) 101.06%);
  // Legacy color, swap out for --gold-900?
  --gold-transparent: rgba(165, 153, 111, 0.25);
  // To later be used for gold button hover state
  --gold-gradient-3-color: linear-gradient(180deg, var(--gold-600) 0%, var(--gold-500) 18.23%, var(--gold-300) 100%);

  /* Typography */
  --font-family-default: 'graphie', sans-serif;
  --font-family-headings: var(--font-family-default);

  --font-size-96: 96px;
  --font-size-72: 72px;
  --font-size-64: 64px;
  --font-size-56: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-36: 36px;
  --font-size-32: 32px;
  --font-size-28: 28px;
  --font-size-24: 24px;
  --font-size-16: 16px;
  --font-size-14: 14px;

  // legacy sizes
  --font-size-30: 30px;
  --font-size-26: 26px;
  --font-size-20: 20px;
  --font-size-12: 12px;

  --font-size-body: var(--font-size-16);

  --font-size-title-desktop: var(--font-size-96);
  --font-size-title-mobile: var(--font-size-48);
  --font-size-h1-desktop: var(--font-size-72);
  --font-size-h1-mobile: var(--font-size-40);
  --font-size-h2-desktop: var(--font-size-64);
  --font-size-h2-mobile: var(--font-size-48);
  --font-size-h3-desktop: var(--font-size-40);
  --font-size-h3-mobile: var(--font-size-32);
  --font-size-h4-desktop: var(--font-size-24);
  --font-size-h4-mobile: var(--font-size-24);
  --font-size-h5-desktop: var(--font-size-24);
  --font-size-h5-mobile: var(--font-size-24);
  --font-size-h6: var(--font-size-16);

  /* Font weights
   * @TODO: investigate why only 400 and 600 work. 500 and 700 did work at one point.
  */
  --font-weight-ultra-light: 200;
  --font-weight-light: 350;
  --font-weight-semi-bold: 400;
  --font-weight-bold: 600;

  --font-weight-body: var(--font-weight-light);

  --line-height-body: 1.5;

  --container-width-xxl: 1790px;

  /* Element colors */
  --bodyBgColor: var(--black);
  --bodyColor: var(--white);
  --closeButtonWidthDesktop: 28px;
  --closeButtonWidthMobile: 20px;

  /* Link */
  --link-color-transition: all 0.2s linear;
  --link-color-black-hover: var(--gray-800);
  --link-color-blue-500-hover: var(--blue-600);
  --link-color-gold-500-hover: var(--gold-400);
  --link-color-gray-300-hover: var(--gray-400);
  --link-color-gray-100-hover: var(--white);
  --link-color-white-hover: var(--gray-100);

  /* Margin values */
  --spacing-0: 0px;
  --spacing-1: 20px;
  --spacing-2: 35px;
  --spacing-3: 55px;
  --spacing-4: 70px;
  --spacing-5: 100px;
  --spacing-6: 40px;

  --header-brand-logo-width: 160px;
  --header-brand-logo-height: 24px;
  --header-brand-mobile-width: 136px;
  --header-brand-mobile-height: 19px;
  --header-height-desktop: 100px;
  --header-height-mobile: 100px;
  --header-submenu-height-desktop: 50px;
  --footer-sponsor-logo-width: 120px;
  --footer-sponsor-logo-height: 24px;

  --footer-margin-top: 80px;

  /* Revisit the zIndex values */
  --z-index-1: 1;
  --z-index-100: 100;
  --z-index-101: 101;
  --z-index-102: 102;
}
